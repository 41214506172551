import { FundPageStatuses } from '@graphql/enums/fundStatuses';

export const getFundPage = (status: FundPageStatuses): string => {
  const pages: { [key in FundPageStatuses]: string } = {
    PARTICIPATION: 'deelname',
    DOCUMENTS: 'documenten',
    SUMMARY: 'inschrijving',
    SIGN: 'ondertekenen',
    TRANSFER: 'overmaken',
    NOT_ALLOWED: '/mogelijk-fonds',
  };

  return pages[status] || '/mogelijk-fonds';
};
